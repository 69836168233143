exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-courses-js": () => import("./../../../src/templates/courses.js" /* webpackChunkName: "component---src-templates-courses-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-it-course-details-js": () => import("./../../../src/templates/it-course-details.js" /* webpackChunkName: "component---src-templates-it-course-details-js" */),
  "component---src-templates-it-courses-js": () => import("./../../../src/templates/it-courses.js" /* webpackChunkName: "component---src-templates-it-courses-js" */),
  "component---src-templates-non-it-course-details-js": () => import("./../../../src/templates/non-it-course-details.js" /* webpackChunkName: "component---src-templates-non-it-course-details-js" */),
  "component---src-templates-non-it-courses-js": () => import("./../../../src/templates/non-it-courses.js" /* webpackChunkName: "component---src-templates-non-it-courses-js" */),
  "component---src-templates-privacy-policy-js": () => import("./../../../src/templates/privacy-policy.js" /* webpackChunkName: "component---src-templates-privacy-policy-js" */),
  "component---src-templates-refund-policy-js": () => import("./../../../src/templates/refund-policy.js" /* webpackChunkName: "component---src-templates-refund-policy-js" */),
  "component---src-templates-study-abroad-page-js": () => import("./../../../src/templates/study-abroad-page.js" /* webpackChunkName: "component---src-templates-study-abroad-page-js" */),
  "component---src-templates-terms-of-use-js": () => import("./../../../src/templates/terms-of-use.js" /* webpackChunkName: "component---src-templates-terms-of-use-js" */)
}

